@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?76444581');
  src: url('../font/fontello.eot?76444581#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?76444581') format('woff2'),
       url('../font/fontello.woff?76444581') format('woff'),
       url('../font/fontello.ttf?76444581') format('truetype'),
       url('../font/fontello.svg?76444581#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?76444581#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-location:before { content: '\e801'; } /* '' */
.icon-user:before { content: '\e802'; } /* '' */
.icon-language:before { content: '\e803'; } /* '' */
.icon-graduation-cap-1:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-link:before { content: '\e806'; } /* '' */
.icon-attention:before { content: '\e807'; } /* '' */
.icon-lightbulb:before { content: '\e808'; } /* '' */
.icon-up-open:before { content: '\e809'; } /* '' */
.icon-adjust:before { content: '\e80a'; } /* '' */
.icon-linkedin-1:before { content: '\e80b'; } /* '' */
.icon-terminal:before { content: '\e80e'; } /* '' */
.icon-website:before { content: '\e80f'; } /* '' */
.icon-sun:before { content: '\e810'; } /* '' */
.icon-sun-filled:before { content: '\e811'; } /* '' */
.icon-sun-1:before { content: '\e812'; } /* '' */
.icon-sun-inv:before { content: '\e813'; } /* '' */
.icon-moon-1:before { content: '\e814'; } /* '' */
.icon-moon-inv:before { content: '\e815'; } /* '' */
.icon-up-open-1:before { content: '\e816'; } /* '' */
.icon-mail-1:before { content: '\e817'; } /* '' */
.icon-firefox:before { content: '\e840'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-github:before { content: '\f09b'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-terminal-1:before { content: '\f120'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-sun-2:before { content: '\f185'; } /* '' */
.icon-moon:before { content: '\f186'; } /* '' */
.icon-graduation-cap:before { content: '\f19d'; } /* '' */
.icon-language-1:before { content: '\f1ab'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-firefox-1:before { content: '\f269'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
